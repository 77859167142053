.carousel-inner {
    margin: 0 auto;
    padding:0rem 0 0 0rem;
    flex-wrap: wrap;
    align-items: auto;
    justify-content: none;
    gap: 10rem;
    width: 100vw;
  }
  p{
    font-family: 'Signika Negative', sans-serif;
  }
  h5{
    font-family: 'Roboto', sans-serif;
  }
  h3{
    text-align: center;
    color: var(--semicolon_black);
    font-size: 1.3rem;
    font-family: 'Signika Negative', sans-serif;
    font-weight:bold;
    text-shadow: 2px 2px #ccc;
    letter-spacing: 0.1rem;
  }
.upcoming_heading{
  padding: 3rem 0 0.5rem 0;
  text-align: center;
  color:#363636 ;
  font-size: 1.3rem;
  font-family: 'Signika Negative', sans-serif;
  font-weight:bold;
  text-shadow: 2px 2px #ccc;
  letter-spacing: 0.1rem;
  margin-bottom:2rem;
}
.carousel-control-prev-icon,.carousel-control-next-icon{
  margin-block-start:85%;
}