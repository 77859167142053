.navbar{
border-bottom: 1px solid gray;
position: fixed;
width: 100%;
background-color: transparent  !important;
-webkit-backdrop-filter: blur(3px);
backdrop-filter: blur(3px);
z-index: 200;
}

.navbar .navbar-nav{
    margin: 0 auto;
    font-family: 'Signika Negative', sans-serif;
}
    .nav-link:hover{
        text-shadow: 0px 0px 15px black;
    }

.navbar ul{
    display: flex;
    margin: 0;
    gap: 3rem;
    margin-top: 15px;
    list-style: none;
    flex-wrap: wrap;
   flex-shrink: 1;
}
.nav-link{
    color: black;
    font-size:16px;
    text-decoration: none;
    display: block;
    font-family: 'Roboto', sans-serif;
} 
.img_logo{
    display: flex;
    width: 58px;
    height:58px;
    flex-wrap: wrap;
    flex-shrink: 1;
    padding: 3px;
    border-radius: 40px;
}


ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        padding-inline-end: 40px;
    }
 .shrink{
    display: flex;
    flex-shrink: 1;
 }
 .navlink{
    text-decoration: none;
 }
 .fa-fa-bars {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: var(--bs-navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

/* Remove border from toggler */
.navbar-toggler {
    border: 0 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 0 !important;
}

/* Lines of the Toggler */
.toggler-icon{
    width: 25px;
    height: 2.5px;
    background-color:grey;
    display: block;
    transition: all 0.2s;
}

/* Adds Space between the lines */
.middle-bar{
    margin: 5px auto;
}

/* State when navbar is opened (START) */
.navbar-toggler .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
    opacity: 0;
    filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
}
/* State when navbar is opened (END) */

/* State when navbar is collapsed (START) */
.navbar-toggler.collapsed .top-bar {
    transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
    opacity: 1;
    filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
    transform: rotate(0);
}
/* State when navbar is collapsed (END) */

/* Color of Toggler when collapsed */
.navbar-toggler.collapsed .toggler-icon {
    background-color: #777777;
}
