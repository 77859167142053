@import url("https://fonts.googleapis.com/css2?family=Bellota+Text:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Bellota Text", cursive;
}
body {
  background-color: white;
}
.about-1 {
  text-align: center;
  padding: 50px;
}
.about-1 h1 {
  padding: 6rem 0 2rem 0;

  font-weight: lighter;
  /* font-size: 0.85rem; */
  text-align: center;
  text-shadow: 2px 2px #ccc;
  letter-spacing: 0.1rem;
  color: var(--semicolon_black);
}
.about-1 p {
  text-align: center;
  padding: 3px;
  color: black;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9rem;
}
.about-item {
  /* margin-bottom: 20px;
  margin-top: 20px;
  background-color: white;
  padding: 80px, 30px;
  box-shadow: 0 0 9px rgba(0, 0, 0 0.6); */
  border-radius: 5px;
  box-shadow: 0px 10px 90px -20px darkgrey;
  padding: 30px;
  margin: 20px;
}
.about-item i {
  font-size: 43px;
  margin: 0;
}
.about-item h3 {
  font-size: 25px;
  margin-bottom: 10px;
}
.about-item hr {
  width: 46px;
  height: 3px;
  background-color: #5fbff9;
  margin: 0 auto;
  border: nome;
}
.about-item p {
  margin-top: 20px;
}
.about-item:hover {
  background-color: #90a2b0;
}
.about-item:hover i,
.about-item:hover h3,
.about-item:hover p {
  color: #fff;
}
.about-item:hover hr {
  background-color: #fdfbfb;
}
.about-item:hover i {
  transform: translateY(-20px);
}
.about-item:hover i,
.about-item:hover h3,
.about-item:hover hr {
  transition: all 400ms ease-in-out;
}
p{
  font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
}
/* footer{
    background: #212226;
    padding: 20px;
}
footer p{
    color: #fff;
} */
