:root {
    --card_width: clamp(16rem, 80vw, 22rem);
    --semicolon_black: #363636;
    --semicolon_white: #ffffff;
  }
  
  .event {
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 5rem;
    text-align: center;
  }

  .event__container {
    margin: 0 auto;
    padding: 2rem 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 3rem;
  }
  
  .event__card {
    width: var(--card_width);
    height: fit-content;
    filter: drop-shadow(0px 100px 134px rgba(0, 0, 0, 0.07))
      drop-shadow(0px 41.7776px 55.982px rgba(0, 0, 0, 0.053739))
      drop-shadow(0px 22.3363px 29.9307px rgba(0, 0, 0, 0.0489203))
      drop-shadow(0px 12.5216px 16.7789px rgba(0, 0, 0, 0.0452479))
      drop-shadow(0px 6.6501px 8.91114px rgba(0, 0, 0, 0.0402674))
      drop-shadow(0px 2.76726px 3.70812px rgba(0, 0, 0, 0.0308617));
    border-radius: 1rem;
    border: 2px solid white;
    position: relative;
  }

  .event__card--name{
    font-family: 'Signika Negative', sans-serif;
    font-weight:bold;
    font-size:1.2rem;
    text-shadow:2px 2px #CCC;
    color: var(--semicolon_black);
    margin-bottom: 0.4rem;
  }
  
  .event__card__detail {
    border-radius: 0 0 1rem 1rem;
    background-color: #f4f4f4;
    padding: 1.2rem 0.6rem 1rem 0.6rem;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    color: var(--semicolon_black);
  }
  
  .event__card__detail--time {
    font-style: italic;
    font-weight: 300;
    font-size: 0.7rem;
  }
  
  .event__card--image {
    height:80px;
    width:80px;
    margin:0.8rem 0;
    border-radius:50%;
    position: relative;
    border: 2px solid #dcd6d6;
    z-index: 100;
    box-shadow: 3px 3px 1px #ccc;
    -webkit-box-shadow: 3px 3px 1px #ccc;
    -moz-box-shadow: 3px 3px 1px #ccc;
  }

  .member__details{
     display: flex;
     align-items: center;
     justify-content: space-around;
  }
  
  .track-list > * {
    transition: all 0.05s;
  }
  
  .track-list.two {
    text-align:center;
    height: 0;
    padding:0 5%;
    overflow: auto;
    transition: height 0.05s ease;
    font-size: 0.75rem;
  }
  .track-list.two.expand {
    height:100px;
    transition: height 0.05s ease;
    padding: 5%;
    font-size: 0.75rem;
    color: var(--semicolon_black);
  }
  
  .track-list.two.expand::-webkit-scrollbar{
    width: 6px;
    background-color: #f5f5f5;
  }
  
  /* Track */
  .track-list.two.expand::-webkit-scrollbar-track {
    background: hsl(0, 0%, 95%);
  }
  
  /* Handle */
  .track-list.two.expand::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: hsl(0, 0%, 79%);
  }
  
  /* Handle on hover */
  .track-list.two.expand::-webkit-scrollbar-thumb:hover {
    background: hsl(0, 0%, 59%);
  }
  
  @media screen and (min-width: 426px) {
    .event__card{
      border-radius: 1.2rem;
      border: 3px solid white;
    }
  }
  
  @media screen and (min-width: 768px) {
    .event__card {
      max-width: 22rem;
      border-radius: 1.4rem;
      border: 4px solid white;
  
    }
    .event__card--name{
      font-size: 1.5rem;
    }
    .event__details {
      padding: 2rem;
      gap: 1rem;
    }
    .event__card__detail{
      padding: 1.5rem 1rem;
    }
    .event__card__detail--time{
      font-size: 0.8rem;
    }
    .track-list.two{
      font-size: 0.85rem;
    }
    .track-list.two.expand{
       font-size: 0.85rem;
       height: 100px;
    }
  }
  