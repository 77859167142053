@import url('https://fonts.googleapis.com/css2?family=Bellota+Text:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
.header {
    font-weight: lighter;
    font-size: 0.85rem;
    text-align: center;
    text-shadow: 2px 2px #ccc;
    letter-spacing: 0.1rem;
    color: var(--semicolon_black);
    padding: 6rem 0 2rem 0;
}
  
  .row1-container {
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .row1-container .box {
  flex-basis: calc(33.33% - 20px);
    background-color: white;
    color: black;
    border: 1px solid grey;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    box-shadow: 5px 5px 5px slategrey;
    margin-bottom: 20px;
    text-decoration: none;
  }
p{
  text-shadow: none;
}
  .row1-container .box h2 {
    margin-top: 0;
  }
  
  .row1-container .box p {
    margin-bottom: 10px;
  }
  
  .row1-container .box .img_resources {
    max-width: 44%;
  }
  
  .row2-container {
    display: flex;
    justify-content: center;
  }
  
  .row2-container .box {
    background-color: white;
    color: black;
     box-shadow: 5px 5px 5px slategrey;
    border: 1px solid grey;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    text-decoration: none;
  }
  
  .row2-container .box h2 {
    margin-top: 0;
  }
  
  .row2-container .box p {
    margin-bottom: 10px;
  }
  
  .row2-container .box .img_resources {
    max-width: 44%;
  }
  
  @media (max-width: 768px) {
    .row1-container .box {
      flex-basis: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .row1-container .box {
      flex-basis: 100%;
    }
  }