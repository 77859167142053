:root {
    --card_width: clamp(16rem, 80vw, 22rem);
    --semicolon_black: #363636;
    --semicolon_white: #ffffff;
  }
  .containerrr{
    padding: 9rem 0 2rem 0;
    
  }
  .containerrr2{
    padding: 2rem 0 ;
  }
  .event {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }

  .event__container {
    margin: 0 auto;
    padding: 3rem 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 3rem;
  }
  
  .event__card {
    width: var(--card_width);
    max-height:fit-content;
    filter: drop-shadow(0px 100px 134px rgba(0, 0, 0, 0.07))
      drop-shadow(0px 41.7776px 55.982px rgba(0, 0, 0, 0.053739))
      drop-shadow(0px 22.3363px 29.9307px rgba(0, 0, 0, 0.0489203))
      drop-shadow(0px 12.5216px 16.7789px rgba(0, 0, 0, 0.0452479))
      drop-shadow(0px 6.6501px 8.91114px rgba(0, 0, 0, 0.0402674))
      drop-shadow(0px 2.76726px 3.70812px rgba(0, 0, 0, 0.0308617));
    border-radius: 1rem;
    border: 2px solid white;
  }

  .event__card__detail--container{
    border-radius: 0 0 1rem 1rem;
    background-color: #f4f4f4;
    padding: 1rem 0.8rem;
    text-align: center;
    max-height: 210px;
    min-height: 200px;
    transition: all 0.5s;
    overflow: auto;
  }
  .intro__event{
    font-weight: lighter;
    font-size: 0.85rem;
  }
  .event__card__detail__page {
    display: flex;
    justify-content: space-between;
    align-items: center;  
    font-size:1.2rem;
  }
  .event__des{
    font-size:0.85rem;
  }
  .event_link{
    color: black;
  }
  .event_link:hover{
    color: #90a2b0;
  }
  
  .event__card__detail--time {
    font-weight: 300;
    font-style: italic;
  }
  
  
  .event__card__image {
    max-width: 100%;
    margin-bottom: 0;
    border-radius: 1rem 1rem 0 0;
  }
  
  
  @media screen and (min-width: 426px) {
    .event h1 {
      font-size: 28px;
      padding-bottom: 24px;
    }
    .event__para {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
    }
    .event__card{
      border-radius: 1.2rem;
      border: 3px solid white;
    }
  }
  
  @media screen and (min-width: 768px) {
    .event h1 {
      font-size: 32px;
      padding-bottom: 28px;
    }
    .event__para {
      max-width: var(--font-para-width);
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
    }
    .event__card {
      max-width: 22rem;
      border-radius: 1.4rem;
      border: 4px solid white;
  
    }
    .event__details {
      padding: 2rem;
      gap: 1rem;
    }
    .event__card__detail{
      padding: 1.4rem 1rem;
    }
    .event__card__detail--time{
      font-weight: 300;
    }
    .event__card__detail--button {
      padding: 0.6rem 1rem;
      border-radius: 30px;
      font-size: medium;
      color: var(--hackrack_black);
    }
  }
  