body{
    scroll-behavior: smooth;
    margin: 0px;
    padding: 0px;
}
#particle_background canvas{
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
}

.top{
    border-bottom: 1px solid #363636;
    font-family: 'Signika Negative', sans-serif;
    color: #363636;
    text-align: center;
    height: 95vh;
    font-size:2.7rem;
    padding: 55px 20px;
    padding-top: 120px;
    font-weight:lighter;
    margin: auto;
    position: relative;
}
/* css for logo */
#logo {
    border-radius: 67px;
    width: 100px;
    margin-top: 6rem;
}


/* css for changing text */
.wrap {
    margin: 4rem auto;
    font-weight: lighter;
    /* display: flex;
    justify-content: space-evenly;
    align-content: flex-end; */
}
/* .landing{
    position: absolute;
    padding: 30px 20px;
} */

/* css for upcoming event button */
.upcoming_btn {
    border: 1px solid #363636;
    text-decoration: none;
    font-size: 18px;
    letter-spacing: .7px;
    font-weight: 600;
    color: #363636;
    padding: 10px 14px;
    border-radius: 5px;
    background-color: white;
}
.upcoming_btn:hover{
    /* font-size: 18.6px; */
    box-shadow: 1.5px 1.5px 3px 0px rgb(114, 114, 114);
}


@media only screen and (max-width: 570px) {
    .top{
        font-size:1.8rem;
        height: 70vh;
    }
    #logo {
        width: 75px;
        margin-top: 30px;
    }
    .upcoming_btn{
        font-size: 14px;
        margin: 2rem;
    }
    .upcoming_btn:hover{
        font-size: 14.6px;
    }
    .wrap{
        margin: 3rem 0 2rem 0;
    }
}
@media only screen and (max-width: 450px) {
    .top{
        font-size:1.2rem;
        height: 67vh;
    }
    #logo {
        width: 67px;
        margin: 1.2rem;
    }
    .upcoming_btn{
        font-size: 11px;
    }
    .upcoming_btn:hover{
        font-size: 11.6px;
    }
}
@media only screen and (max-width:300px){
    .top{
        font-size: 28px;
    }
    #logo{
        width: 65px;
    }
    .landing{
        padding-top: 5px;
    }
    .upcoming_btn{
        padding: 5px 5px;
    }
}
