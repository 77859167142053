.team_sec{
    margin: 4rem 2rem 2rem 2rem;
    text-align: center;
    font-family: 'Roboto', sans-serif;
}
.teamsec_heading{
    font-size: 2.3rem;
    margin-bottom: 1rem;
    font-family: 'logo';
}
.responsive-cell-block{
    min-height:75px;
  }
  .responsive-container-block{
    min-height:75px;
    height:fit-content;
    width:100%;
    display:flex;
    flex-wrap:wrap;
    margin-top:0px;
    margin-right:auto;
    margin-bottom:0px;
    margin-left:auto;
    justify-content:space-evenly;
  }
  .img-wrapper{
    width:100%;
  }
  .team-card{
    display:flex;
    flex-direction:column;
    align-items:center;
  }
  .name{
    font-size:1rem;
    color: black;
    margin-top:2px;
    margin-right:0px;
    margin-bottom:2px;
    margin-left:0px;
    font-weight:600;
    letter-spacing: 0.03em;
  }
  .position{
    font-size:0.8rem;
    color:black;
    margin-top:0px;
    margin-right:0px;
    margin-bottom:50px;
    margin-left:0px;
  }
  .team-img{
    width:50%;
    height:100%;
  }
  .team-card-container{
    width:280px;
    margin:1rem 0 0 0;
  }
  .teamsec_btn{
    display: inline-block;
    padding: 12px;
    font-size: 15px;
    line-height: 1;
    background: white;
    color:black;
    font-weight: 600;
    letter-spacing: 0.03em;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    border: 0.1rem solid black;
    border-radius: 0.5rem;
  }
  .teamsec_btn:hover{
    background-color:#90a2b0 ;
    color: black;

 }
  @media screen and (min-width:1600px){
    .teamsec_heading{
        font-size: 3.8rem;
        margin: 2rem;
    }
    .team-img{
        width: 60%;
    }
    .name{
        font-size: 1.5rem;
    }
    .position{
        font-size: 1.3rem;
    }
    .teamsec_btn{
        font-size: 1.8rem;
        padding: 2rem;
    }
  }
  @media (max-width: 500px){
    .outer-container{
      padding:10px 20px 10px 20px;
    }
    .section-head-text{
      text-align:center;
    }
    .team_sec{
        margin: 2rem 0;
    }
  } 

  .wk-desk-1{
    width: 8.333333%;
  }
  .wk-desk-2{
    width: 16.666667%;
  }
  .wk-desk-3{
    width: 25%;
  }
  .wk-desk-4{
    width: 33.333333%;
  }
  .wk-desk-5{
    width: 41.666667%;
  }
  .wk-desk-6{
    width: 50%;
  }
  .wk-desk-7{
    width: 58.333333%;
  }
  .wk-desk-8{
    width: 66.666667%;
  }
  .wk-desk-9{
    width: 75%;
  }
  .wk-desk-10{
    width: 83.333333%;
  }
  .wk-desk-11{
    width: 91.666667%;
  }
  .wk-desk-12{
    width: 100%;
  }
  
  @media (max-width: 1024px) {
    .wk-ipadp-1{
      width: 8.333333%;
    }
    .wk-ipadp-2{
      width: 16.666667%;
    }
    .wk-ipadp-3{
      width: 25%;
    }
    .wk-ipadp-4{
      width: 33.333333%;
    }
    .wk-ipadp-5{
      width: 41.666667%;
    }
    .wk-ipadp-6{
      width: 50%;
    }
    .wk-ipadp-7{
      width: 58.333333%;
    }
    .wk-ipadp-8{
      width: 66.666667%;
    }
    .wk-ipadp-9{
      width: 75%;
    }
    .wk-ipadp-10{
      width: 83.333333%;
    }
    .wk-ipadp-11{
      width: 91.666667%;
    }
    .wk-ipadp-12{
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .wk-tab-1{
      width: 8.333333%;
    }
    .wk-tab-2{
      width: 16.666667%;
    }
    .wk-tab-3{
      width: 25%;
    }
    .wk-tab-4{
      width: 33.333333%;
    }
    .wk-tab-5{
      width: 41.666667%;
    }
    .wk-tab-6{
      width: 30%;
    }
    .wk-tab-7{
      width: 58.333333%;
    }
    .wk-tab-8{
      width: 66.666667%;
    }
    .wk-tab-9{
      width: 75%;
    }
    .wk-tab-10{
      width: 83.333333%;
    }
    .wk-tab-11{
      width: 91.666667%;
    }
    .wk-tab-12{
      width: 100%;
    }
    .name{
        font-size: 0.8rem;
    }
    .position{
        font-size: 0.7rem;
    }
    .teamsec_btn{
        font-size: 0.85rem;
    }
  }
  
  @media (max-width: 500px) {
    .wk-mobile-1{
      width: 8.333333%;
    }
    .wk-mobile-2{
      width: 16.666667%;
    }
    .wk-mobile-3{
      width: 25%;
    }
    .wk-mobile-4{
      width: 33.333333%;
    }
    .wk-mobile-5{
      width: 41.666667%;
    }
    .wk-mobile-6{
      width: 50%;
    }
    .wk-mobile-7{
      width: 58.333333%;
    }
    .wk-mobile-8{
      width: 66.666667%;
    }
    .wk-mobile-9{
      width: 75%;
    }
    .wk-mobile-10{
      width: 83.333333%;
    }
    .wk-mobile-11{
      width: 91.666667%;
    }
    .teamsec_heading{
            font-size: 1.6rem;
    }
    .team-img{
        width:80%;
        height:100%;
      }
    .name{
        font-size: 0.7rem;
    }
    .position{
        font-size: 0.6rem;
    }
    .teamsec_btn{
        font-size: 0.7rem;
    }
  }    