 .container{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: row;
    overflow: auto;
    margin-top: auto;
}
/*
.who{
    background-color: white;
    color: black;
    text-align: center;
    height: 30vh;
    width: 60vw;
    border: 2px solid black;
    position: relative;
}
.container1{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: row;
    overflow: auto;
}
.what{
    background-color: white;
    color: black;
    text-align: center;
    height: 30vh;
    width: 60vw;
    border: 2px solid black;
    position: relative;
} */

.parent{
    width: 85vw;
    margin: auto;
    margin-top:4rem;
    text-align: center;
}

.who_img{  
    height:250px;
    width:600px;
    margin-right: 3rem;
    border-radius: 1rem;
    /* filter: drop-shadow(0px 100px 134px rgba(0, 0, 0, 0.07))
      drop-shadow(0px 41.7776px 55.982px rgba(0, 0, 0, 0.053739))
      drop-shadow(0px 22.3363px 29.9307px rgba(0, 0, 0, 0.0489203))
      drop-shadow(0px 12.5216px 16.7789px rgba(0, 0, 0, 0.0452479))
      drop-shadow(0px 6.6501px 8.91114px rgba(0, 0, 0, 0.0402674))
      drop-shadow(0px 2.76726px 3.70812px rgba(0, 0, 0, 0.0308617)); */
}
.heading{
    text-align: center;
    color:#363636 ;
    font-size: 1.3rem;
    font-family: 'Signika Negative', sans-serif;
    font-weight:bold;
    text-shadow: 2px 2px #ccc;
    letter-spacing: 0.1rem;
    margin-bottom: 1rem;
}
.description{
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
}
.flex,.flex1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: justify;
    margin-bottom: 3rem;
    width:75vw;
    padding:2rem 0;
}
.what_img{
    height:250px;
    width: 600px;
    margin-left: 3rem;
    border-radius: 1rem;
    /* filter: drop-shadow(0px 100px 134px rgba(0, 0, 0, 0.07))
      drop-shadow(0px 41.7776px 55.982px rgba(0, 0, 0, 0.053739))
      drop-shadow(0px 22.3363px 29.9307px rgba(0, 0, 0, 0.0489203))
      drop-shadow(0px 12.5216px 16.7789px rgba(0, 0, 0, 0.0452479))
      drop-shadow(0px 6.6501px 8.91114px rgba(0, 0, 0, 0.0402674))
      drop-shadow(0px 2.76726px 3.70812px rgba(0, 0, 0, 0.0308617)); */
}

@media screen and (max-width: 768px){
    .flex{
        flex-direction: column;
    }
    .flex1{
        flex-direction: column-reverse;
    }
    .who_img{
        margin-right: 0;
        width: 80vw;
    }
    .what_img{
        margin-left: 0;
        width: 80vw;
    }
}