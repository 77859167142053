@import url('https://fonts.googleapis.com/css2?family=Bellota+Text:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
*
{
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: 'Bellota Text', cursive;
}
/*body
{
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: 100vh;
   width: 100%;
   background: #f2f3f7;
}*/
.container
{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
}
.container .card
{
   width:250px;
   padding: 1rem;
   margin: 20px;
   /* background: white;
   box-shadow: 0.6em 0.6em 1.2em #d2dce9,
               -0.5em -0.5em 1em #ffffff;
   border-radius: 20px; */
   filter: drop-shadow(0px 100px 134px rgba(0, 0, 0, 0.07))
      drop-shadow(0px 41.7776px 55.982px rgba(0, 0, 0, 0.053739))
      drop-shadow(0px 22.3363px 29.9307px rgba(0, 0, 0, 0.0489203))
      drop-shadow(0px 12.5216px 16.7789px rgba(0, 0, 0, 0.0452479))
      drop-shadow(0px 6.6501px 8.91114px rgba(0, 0, 0, 0.0402674))
      drop-shadow(0px 2.76726px 3.70812px rgba(0, 0, 0, 0.0308617));
    border-radius: 1rem;
    border: 2px solid white;
   /* z-index: -1; */
}
.container .card .content
{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.container .card .content .imgBx
{
   width: 140px;
   height: 140px;
   border-radius: 50%;
   position: relative;
   margin-bottom:10px;
   overflow: hidden;
}
.container .card .content .imgBx img
{
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   object-fit: cover;
}
.container .card .content .contentBx h4
{
   color: black;
   font-size: 1.2rem;
   font-weight: bold;
   text-align: center;
   letter-spacing:0.03rem;
}
.container .card .content .contentBx h5
{
   color: #6c758f;
   font-size: 0.85rem;
   /* font-weight: bold; */
   text-align: center;
} 
.container .card .content .sci
{
   /* margin-top: 10px; */
} 
.sci a
{
   text-decoration: none;
   color: #6c758f;
   font-size: 22px;
   margin: 10px;
} 
/* .container .card .content .sci a:hover
{
   color: #0196e3;
}  */
.Team
{
   padding: 9rem 0 2rem 0;
    font-weight: lighter;
    font-size: 0.85rem;
    text-align: center;
    text-shadow: 2px 2px #ccc;
    letter-spacing: 0.1rem;
    color: var(--semicolon_black);
    }

/*   colors
        #f2f3f7
        #d2dce9
        #36187d
        #6c758f
        #0196e3
 */ 
.branchContent{
   text-align: center;
 }