@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300&display=swap');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-family:'Signika Negative', sans-serif;
  font-weight: 400;
}

.footer{
  /*background-color: #f7f8fb;*/
  background-color: white;
  padding:6rem 0 1rem 0;
}

.list a{
  color: black;
}

.line{
    height: 1px;
    background-color: gray;
    margin: 1.2rem auto 1rem auto;
}


.footer .social-handles{
  text-align: center;
  padding: 19px;
  color: black;
}

.footer .social-handles a{
  font-size: 20px;
  color: inherit;
 /* border: 2px solid black; */
  height: 39px;
  width: 39px;
  line-height: 38px;
  display: inline-block;
  border-radius: 50%;
  margin: 0 8px;
}

.footer .social-handles a:hover{
  opacity: 0.75;
}


.footer .copyright{
  margin-top: 15px;
  text-align: center;
  font-size: 15px;
  color: gray;
}
.img{
  width: 30px;
  height: 30px;
}